<style lang="scss">
    .width-150{
        /*width:150px;*/
        /*.el-input__inner{width:100%;}*/
        .el-input .el-input__icon{line-height:22px;}
    }
    .width-600 {
        /*width: 600px;*/
        /*.el-input__inner{width:100%;}*/
        .el-input .el-input__icon{line-height:22px;}
    }
</style>
<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup">    
                <div  class="h-handle-button">
                    <div class="h-b">
                        <el-button type="primary"  size="small"  @click="exportExcel" v-right-code="'Stockinfo:Exportsummarystock'">导出汇总库存</el-button>
                    </div>
                </div>
                <div class="h-handle-choose">
                    &nbsp;&nbsp;&nbsp;&nbsp;<el-checkbox v-model="isShowZeroStocksum" @change="sumquerynotZero">隐藏零库存货品</el-checkbox>
				</div>
				<div class="h-handle-choose">
                    &nbsp;&nbsp;&nbsp;&nbsp;<el-checkbox v-model="isShowStopStocksum" @change="sumquerynotStop">隐藏停用货品</el-checkbox>
                </div>
            </div>
            <p slot="elList">
                <el-table   ref="stockinfoListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col,ignoreField)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <!--<span v-if="col.FieldName==='ProductCode'"><el-button type="text" @click="batchDetail(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</el-button></span>-->
                            <span v-if="col.FieldName==='ProductCode'"><el-button type="text">{{ scope.row[col.FieldName] }}</el-button></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
	import sha from '../../../../../scripts/sha.js';
    export default {
        mounted() {
            this.Utils.lazy(() => {
                this.Event.$on("reloadPageList", () => this.reloadPageList());
            });
            var _this = this;
            this.initialize();
        },
        data() {
            return {
                ignoreField: [],
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10,
                    PageParams: {
                        zerostock:true,
						stopstock:true 
                    }
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
                stockDealOptions: {
                    visible: false,
                    size: 'large'
                },
                stockDealConfig: {
                    isDetailDisplay: Object.freeze(true)
                },
                queryParamDetail: {
                    pageIndex: 1,
                    pageSize: 10,
                    Params: {}
                },
                ProductCode: '',
                isShowZeroStocksum:true,
				isShowStopStocksum:true,
            }
        },
        props: {
            config: {
                isDetailDisplay: false
            },
            option: {}
        },
        methods: {
            reloadPageList: function () {
                this.onPageChange(this.queryParam);
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
               var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/stockinfo/searchmanagesummarystocklist", "post", param, data => {
                    console.log(data);
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
            batchDetail(row) {
       /*         this.queryParamDetail.Params = {};
                this.queryParamDetail.Params.ProductCode = { fieldName: 'ProductCode', fieldValue: row.ProductCode };
                this.queryParamDetail.Params.InventoryType = { fieldName: 'InventoryType', fieldValue: row.InventoryType };
                this.$ajax.send("omsapi/stock/searchsummarystockdetail", "post", this.queryParamDetail, (data) => {
                    data.Result.ProductCode = row.ProductCode;
                    data.Result.ProductName = row.ProductName;
                    data.Result.OuterID = row.OuterID;
                    data.Result.ProductUnit = row.ProductUnit;
                    data.Result.ProductBarCode = row.ProductBarCode;
                    data.Result.DisplayInventoryType = row.DisplayInventoryType;
                    this.Event.$emit("clearStockBatchForm");
                    this.onChangeEditDataSource(data.Result);
                }); */
            },
            stockDetail(row) {
                this.ProductCode = row.ProductCode;
                this.stockDealOptions.visible = true;
            },
            exportExcel: function () {
                var _this = this;
                if (_this.dataSource.TotalCount == 0) {
                    this.Utils.messageBox(`没有需要导出的数据.`, "error");
                    return false;
                } 
                this.queryParam.PageParams.Zerostock = this.isShowZeroStocksum;
                this.queryParam.PageParams.Stopstock = this.isShowStopStocksum;
                var str = encodeURIComponent(JSON.stringify(_this.queryParam));
              //  var urlFile = "omsapi/stock/stockinfo/exportsummarystock?queryParam=" + str;
					var urlFile = "omsapi/stockinfo/exportsummarystock?queryParam=" + str + "&token=" + sha
				.getToken1();
					_this.Utils.export(urlFile);
            },
            sumquerynotZero: function (val) {
                this.queryParam.PageParams.zerostock =val;
                this.onPageChange(this.queryParam);
			},
			sumquerynotStop: function (val) {
                this.queryParam.PageParams.stopstock =val;
                this.onPageChange(this.queryParam);
            }
        },
        components: {
        }
    }
</script>